import Vue from 'vue'
import Vuex from 'vuex'
import SStorage  from '../utils/storage.js';//引用缓存
const localSS = new SStorage()
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    a:'1',
    aliHtml:localSS.get('aliHtml') || '',
    orderObj:localSS.get('orderObj') || {},
    orderRes:localSS.get('orderRes') || {},
    isShowLoading: false,
    h5PayFixed:false,
    environment:'h5',//默认为H5环境 另一种是wechat
  },
  getters: {
    getA(state){
      return state.a
    },
    getAliHtml(state){
      return state.aliHtml
    },
    getOrderObj(state){
      return state.orderObj
    },
    getOrderRes(state){
      return state.orderRes
    },
    getEnvironment: state => {
      return state.environment
    },
    getH5PayFixed: state => {
      return state.h5PayFixed
    }
  },
  mutations: {
    changeAliHtml(state,treeDom) {
      localSS.set('aliHtml',treeDom,15*60*1000)
      state.aliHtml = treeDom
    },
    changeOrderObj(state,obj){
      localSS.set('orderObj',obj,15*60*1000)
      state.orderObj = obj
    },
    changeOrdeRes(state,obj){
      localSS.set('orderRes',obj,15*60*1000)
      state.orderObj = obj
    },
    // 展示loading的方法
    showLoading(state) {
      state.isShowLoading = true
    },
    // 隐藏loading的方法
    hideLoading(state) {
      state.isShowLoading = false
    },
    // 展示支付查询的界面
    showH5PayFixed(state) {
      state.h5PayFixed = true
    },
    hideH5PayFixed(state){state.h5PayFixed = false},
    // 设置全局环境
    changeEnv: (state,string)=>{
      state.environment = string
    }
  },
  actions: {
    setAliHtml({ commit },value){
      commit("changeAliHtml",value);
    },
    setOrderObj({commit},obj){
      commit('changeOrderObj',obj)
    },
    setOrderRes({commit},obj){
      commit('changeOrdeRes',obj)
    },
    setEnvironment(store,bool){
      let _env = bool ? 'wechat': 'h5';
      store.commit('changeEnv',_env);
    }
  },
  modules: {
  }
})
