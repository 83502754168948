const api_config = {
    // BaseURL:'http://local.live.test'
    // BaseURL:'http://gdkjb.51xuekexue.com'
    // BaseURL: http://local.biancheng-buy.test
    // BaseURL:process.env.NODE_ENV === 'production' ? 'https://api-bc-test.51xuekexue.com' : '/test' 
    // BaseURL:process.env.NODE_ENV === 'production' ? 'https://api-bc-test.51xuekexue.com' : 'https://api-bc-test.51xuekexue.com' 
    // BaseURL:process.env.NODE_ENV === 'production' ? 'https://api-bc-test.51xuekexue.com' : 'http://local.biancheng-buy.test' 
    // BaseURL:process.env.NODE_ENV === 'production' ? 'https://crm-api.xiaoyangbiancheng.com' : 'http://local.biancheng-buy.test' 
    BaseURL:process.env.NODE_ENV === 'production' ? 'https://crm-api.xiaoyangbiancheng.com' : 'https://crm-api.xiaoyangbiancheng.com' 
  }

  export default api_config
  