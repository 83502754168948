<template>
  <div id="app">
    <router-view/>
    <div class="zhezhaoceng" v-show="isShowLoading" >
      <van-loading
      v-show="isShowLoading"
      size="24px"
      color="#1989fa"
      vertical
    >加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { mapState} from 'vuex'
// import { Dialog } from 'vant'; 
// import {is_weixin,urlencode, callbackBaseUrl} from '@/utils/utils';
// import {is_weixin,urlencode, callbackBaseUrl,objectToQuery} from '@/utils/utils';
// import wx from 'weixin-js-sdk';
export default {
  computed: {
    ...mapState(['isShowLoading']),
    // ...mapGetters(['getEnvironment','getH5PayFixed']),
    classObject: function () {
      if(this.isShowLoading){
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'relative';
        return 1
      }else{
        document.body.style.overflow = ''
        document.body.style.position = ''
        return 2
      }
    }
  },
  data(){
    return {
    }
  },
  created(){
    
  },
  async mounted(){
    
  },
  methods:{
  }
}
</script>
<style>
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.van-loading {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.zhezhaoceng{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
}
.zhezhaocengjiashen{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}
.kongzhuangtai{
    position: fixed;
    transform: translate(0%, -10%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
</style>
