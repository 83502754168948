import router from "@/router";
import Http from '@/http/axios';//引入请求
import { Toast } from "vant";
export const is_weixin = ()=>{
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger'){
    return true;
  } else {
    return false;
  }
}

export function wxPay(payObj,orders, type='normal'){
  if (typeof WeixinJSBridge == "undefined"){
    if( document.addEventListener ){
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady(payObj,orders,type), false);
    }else if (document.attachEvent){
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady(payObj,orders,type)); 
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(payObj,orders,type));
    }
  }else{
    onBridgeReady(payObj,orders,type);
  }
}

function onBridgeReady (payObj,orders,type) {
  window.WeixinJSBridge.invoke(
     'getBrandWCPayRequest', payObj,
     function(res){
      if(res.err_msg == "get_brand_wcpay_request:ok" ){
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        Http.getOrderStatus({out_trade_no:orders.out_trade_no},false,{retry:5}).then(res=>{
          // 验证订单
          if(res.code == 200 && res.data.status == 2){
            if(type==='normal'){
              router.push({path:'/payEnd',query:orders})
            }else{
              router.push({path:'/activityEnd',query:orders})
            }
          }else{
            Toast('订单交易失败，请联系客服处理')
            if(type==='normal'){
              router.push({path:'/payEnd',query:{pay_error:true,...orders}})
            }else{
              router.push({path:'/activityEnd',query:{pay_error:true,...orders}})
            }
            
          }
        })
      }else{
        // console.log(res,'未付款')
      } 
  }); 
  
  // window.WeixinJSBridge.invoke(
  //    'getBrandWCPayRequest', {
  //       "appId": "wx4591a3644cdd46d2",//公众号ID，由商户传入     
  //       "nonceStr": "731auq09yavjce84upy5dbuqv78sdbio",//随机串
  //       "package": "prepay_id=wx301611356685828d3fbccb688fb08b0000",
  //       "paySign": "5227C2B40822EEBDF17C34D995A48E9E",//微信签名 
  //       "signType": "MD5",//微信签名方式：
  //       "timeStamp": "1669795895",//时间戳，自1970年以来的秒数
  //       "times": 1669795895,              
  //    },
  //    function(res){
  //     if(res.err_msg == "get_brand_wcpay_request:ok" ){
  //       // 使用以上方式判断前端返回,微信团队郑重提示：
  //       //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
  //       console.log(res)
  //     }else{
  //       console.log(res,'未付款')
  //     } 
  // }); 
}

export function nowTimeStamp (){

}

export function urlencode (str) {
  str = (str + '').toString();
  return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28'). replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
}

// export const callbackBaseUrl = 'https://bc-goods-test.51xuekexue.com/'
export const callbackBaseUrl = process.env.NODE_ENV === 'production' ? 'https://bc-goods.51xuekexue.com/' : 'https://bc-goods-test.51xuekexue.com/' 


export function objectToQuery() {
	let obj = arguments[0];
	let prefix = arguments[1];
	if (typeof obj !== "object") return "";
	const attrs = Object.keys(obj);
	return attrs.reduce((query, attr, index) => {
		// 判断是否是第一层第一个循环
		if (index === 0 && !prefix) query += "?";
		if (typeof obj[attr] === "object") {
			const subPrefix = prefix ? `${prefix}[${attr}]` : attr;
			query += this.objectToQuery(obj[attr], subPrefix);
		} else {
			if (prefix) {
				query += `${prefix}[${attr}]=${obj[attr]}`;
			} else {
				query += `${attr}=${obj[attr]}`;
			}
		}
		// 判断是否是第一层最后一个循环
		if (index !== attrs.length - 1) query += "&";
		return query;
	}, "");
}