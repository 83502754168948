import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/drawCert',
  },
  {
    path: '/drawCert',
    name: 'drawCertPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/drawCert/DrawCertPage.vue')
  }
]

const router = new VueRouter({
  routes,
  mode:'history',
})
// 跳转页面不置顶的情况
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0; 
  document.documentElement.scrollTop = 0; 
  window.pageYOffset = 0;
  next();
});
export default router
