import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/vant.js';//引入Vant
import 'vant/lib/index.css';//引用Vant样式
import SStorage  from './utils/storage.js';//引用缓存
import Http from './http/axios';//引入请求
import '../public/font/iconfont.css'
Vue.prototype.$Http = Http;//挂载
const localSS = new SStorage()
Vue.prototype.$Storage = localSS;//挂载缓存

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
