import Vue from 'vue'
import { Button, Icon, Swipe, SwipeItem, Collapse, CollapseItem,  Sticky, Empty, Toast, Dialog, Loading,
         Slider, Popup, Overlay, IndexBar, IndexAnchor, Cell, Divider ,Grid, GridItem,Circle,Tab,Tabs,NavBar,
         Image,Pagination,Checkbox,RadioGroup,Radio,CellGroup,CountDown,ImagePreview,Form,Field,Picker,Step, Steps
        } from 'vant'

Vue.use(Button)
  .use(Icon)
  .use(Swipe)
  .use(SwipeItem)
  .use(Collapse)
  .use(CollapseItem)
  .use(Sticky)
  .use(Empty)
  .use(Toast)
  .use(Dialog)
  .use(Loading)
  .use(Slider)
  .use(Popup)
  .use(Overlay)
  .use(IndexBar)
  .use(IndexAnchor)
  .use(Cell)
  .use(Divider)
  .use(Grid)
  .use(GridItem)
  .use(Circle)
  .use(Tab)
  .use(Tabs)
  .use(NavBar)
  .use(Image)
  .use(Pagination)
  .use(Checkbox)
  .use(RadioGroup)
  .use(Radio)
  .use(CellGroup)
  .use(CountDown)
  .use(Form)
  .use(Field)
  .use(Picker)
  .use(Step)
  .use(Steps)
  .use(ImagePreview);
