import FACTURL from '../utils/config'
import axios from 'axios'
import { Notify  } from 'vant';
import {is_weixin} from '@/utils/utils'
// import Vue from 'vuex';
import store from '@/store';
// import { get_token , get_hospitalId} from 'utils/auth'
import SStorage  from '../utils/storage.js';//引用缓存
import API from './api'
import router from '@/router';

const axioslocalSS = new SStorage();

let instance = axios.create({
  baseURL: FACTURL.BaseURL,
  timeout: 5000,
  // headers: {
  //   post: {
  //     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  //   }
  // },
  withCredentials: false
})


const Http = {} //包裹请求方法的容器

//请求格式/参数的统一
for (let key in API) {
  let api = API[key] //url method
  //async作用：避免进入回调地狱
  Http[key] = async function(
    params, //请求参数get:url,put,post,patch(data),delete:url
    isFormData = false, //标识是否是form-data请求
    config = {} //配置参数
  ) {
    let newParams = {}

    //content-type是否是form-data的判断
    if (params && isFormData) {
      newParams = new FormData()
      for (let i in params) {
        newParams.append(i, params[i])
      }
    } else {
      newParams = params
    }
    //不同请求的判断
    let response = {} //请求的返回值
    if (api.method === 'put' || api.method === 'post' || api.method === 'patch') {
      try {
        response = await instance[api.method](api.url, newParams, config)
      } catch (err) {
        response = err
      }
    } else if (api.method === 'delete' || api.method === 'get') {
      config.params = newParams
      try {
        response = await instance[api.method](api.url, config)
      } catch (err) {
        response = err
      }
    }
    return response //请求的响应值
  }
}

// 添加请求拦截器 ，加入token
// let overone = 1
instance.interceptors.request.use(
  function(config) {
    store.commit('showLoading');
    // 注意 config.headers.common 为underfind
    if(axioslocalSS.get('token')){
      config.headers['token'] = axioslocalSS.get('token');
    }
    config.headers['sourceType'] = is_weixin() ? 'wechat': 'h5';
    // if (get_token()) {
    //   config.headers.common['authToken'] = get_token()
    //   config.headers.common['protected'] = get_hospitalId()
    // }
    if(config.retry){
      config.headers['Content-Type'] = 'application/json';
      // config.headers['s']
    }
    return config
  },
  function(error) {
    store.commit('hideLoading');
    return Promise.reject(error)
  }
)

// Add a response interceptor 响应拦截器
instance.interceptors.response.use(
  function(response) {
    var config = response.config;
    if(!config || !config.retry){
    store.commit('hideLoading');
    if (response.status === 200) {
      if (response.data.code == 20002 || response.data.code == 20003 || response.data.code == 20003) {
        // 20002 20004 token 过期
        axioslocalSS.remove();
        Notify({ type: 'warning', message:'身份验证已过期'});
        let {token,...params} = router.history.current.query;//eslint-disable-line no-unused-vars
        router.push({path:'/',query:params});//去掉token
        // router.replace({query:params});//去掉token
        router.go(0);//刷新
        return Promise.reject(response);
      }
      if(response.data.code == 200 || response.data.code == 0){
        return Promise.resolve(response.data);
      }else{
        if((response.data.constructor === String) && response.data.indexOf('<form') > -1){
          return Promise.reject(response);
        }
        Notify({ type: 'warning', message: response.data.msg});
        return Promise.reject(response);
      }
      
      } else {
        return Promise.reject(response);
      }
    }
    /**
     * 注意。轮询以response.data.data.status 的结果为轮询触发器
     */
     // 设置变量以跟踪重试次数
    if(response.status === 200){
      if (response.data.code == 20002 || response.data.code == 20003 || response.data.code == 20003) {
        // 20002 20004 token 过期
        axioslocalSS.remove();
        Notify({ type: 'warning', message:'身份验证已过期'});
        store.commit('hideLoading');
        return Promise.reject(response);
        
      }
      if(response.data.code == 200 || response.data.code == 0){
        if(response.data.data.status == 2){
          store.commit('hideLoading');
          return Promise.resolve(response.data);
        }
      }

    } else {
      store.commit('hideLoading');
      return Promise.reject(response);
    }
    config.__retryCount = config.__retryCount || 0;
    // 判断是否超过总重试次数
    if (config.__retryCount >= config.retry) {
      // 返回错误并退出自动重试
      store.commit('hideLoading');
      return Promise.reject(response);
    }
    // 增加重试次数
    config.__retryCount += 1;
    //打印当前重试次数
    // console.log(config.url +' 自动重试第' + config.__retryCount + '次');
    // 创建新的Promise
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
          resolve();
      }, config.retryDelay || 5000);
    });
    // 返回重试请求
    return backoff.then(function () {
      return instance(config);
  });
  },
  function(error) {
    var config = error.config;
    // 如果配置不存在或未设置重试选项，则拒绝
    if (!config || !config.retry){
      store.commit('hideLoading');
      if (error && error.response) {
          // 1.公共错误处理
          // 2.根据响应码具体处理
          switch (error.response.status) {
            case 400:
              error.msg = '错误请求'
              break;
            case 401:
              error.msg = '未授权，请重新登录'
              break;
            case 403:
              // 后端返403时就是token过期了，此时清空token并且跳转页面
              error.msg = '拒绝访问'
              break;
            case 404:
              error.msg = '请求错误,未找到该资源'
              break;
            case 405:
              error.msg = '请求方法未允许'
              break;
            case 408:
              error.msg = '请求超时'
              break;
            case 500:
              error.msg = '服务器端出错'
              break;
            case 501:
              error.msg = '网络未实现'
              break;
            case 502:
              error.msg = '网络错误'
              break;
            case 503:
              error.msg = '服务不可用'
              break;
            case 504:
              error.msg = '网络超时'
              break;
            case 505:
              error.msg = 'http版本不支持该请求'
              break;
            case 20002:
              error.msg = '未授权，请重新登录'
              break;
            case 20003:
              error.msg = '未授权，请重新登录'
              break;
            case 20004:
              error.msg = '未授权，请重新登录'
              break;
            default:
              error.msg = `连接错误${error.response.status}`
              break;
          }
      } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
          error.msg = `服务器响应超时，请重试`
          // Message.error('服务器响应超时，请刷新当前页')
        }
        // Message.error('连接服务器失败')
      }
      Notify({ type: 'danger', message: error.msg});
      // Message.error(error.msg);
      return Promise.reject(error);
    } 

    // 设置变量以跟踪重试次数
    config.__retryCount = config.__retryCount || 0;

    // 判断是否超过总重试次数
    if (config.__retryCount >= config.retry) {
        // 返回错误并退出自动重试
        // return Promise.reject(err);
        store.commit('hideLoading');
        if (error && error.response) {
          // 1.公共错误处理
          // 2.根据响应码具体处理
          switch (error.response.status) {
            case 400:
              error.msg = '错误请求'
              break;
            case 401:
              error.msg = '未授权，请重新登录'
              break;
            case 403:
              // 后端返403时就是token过期了，此时清空token并且跳转页面
              error.msg = '拒绝访问'
              break;
            case 404:
              error.msg = '请求错误,未找到该资源'
              break;
            case 405:
              error.msg = '请求方法未允许'
              break;
            case 408:
              error.msg = '请求超时'
              break;
            case 500:
              error.msg = '服务器端出错'
              break;
            case 501:
              error.msg = '网络未实现'
              break;
            case 502:
              error.msg = '网络错误'
              break;
            case 503:
              error.msg = '服务不可用'
              break;
            case 504:
              error.msg = '网络超时'
              break;
            case 505:
              error.msg = 'http版本不支持该请求'
              break;
            case 20002:
              error.msg = '未授权，请重新登录'
              break;
            case 20003:
              error.msg = '未授权，请重新登录'
              break;
            case 20004:
              error.msg = '未授权，请重新登录'
              break;
            default:
              error.msg = `连接错误${error.response.status}`
              break;
          }
        } else {
          // 超时处理
          if (JSON.stringify(error).includes('timeout')) {
            error.msg = `服务器响应超时，请重试`
            // Message.error('服务器响应超时，请刷新当前页')
          }
          // Message.error('连接服务器失败')
        }
        Notify({ type: 'danger', message: error.msg});
        // Message.error(error.msg);
        return Promise.reject(error);
    }

    // 增加重试次数
    config.__retryCount += 1;

    //打印当前重试次数
    // console.log(config.url +' 自动重试第' + config.__retryCount + '次');

    // 创建新的Promise
    var backoff = new Promise(function (resolve) {
        setTimeout(function () {
            resolve();
        }, config.retryDelay || 1);
    });

    // 返回重试请求
    return backoff.then(function () {
        return instance(config);
    });






    
  }
)
/* 这里是原版的所有请求结束 */
/* 这里是原版的所有请求结束 */


export default Http
